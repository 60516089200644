import React, { useState } from "react";
import Container from "../components/Container";
import InputField from "../components/InputField";
import validateResetFields from "../util/validations/validateResetFields";

const ForgotPassword = () => {
  const [resetPassword, setResetPassword] = useState({
    confirmPassword: "",
    password: "",
  });
  const [validationErrors, setErrors] = useState({});

  const handleResetPassword = () => {
    const { isValid, errors } = validateResetFields(resetPassword);
    if (isValid) {
      // make async api call once api is ready
    } else {
      setErrors(errors);
    }
    console.log(isValid, errors);
  };

  const handlePasswordChange = ({ target }) => {
    const clonedPasswordSets = { ...resetPassword };
    const clonedErrors = { ...validationErrors };
    const { name, value } = target;
    clonedPasswordSets[name] = value;
    clonedErrors[name] = "";
    setResetPassword(clonedPasswordSets);
    setErrors(clonedErrors);
  };

  return (
    <Container>
      <div className="signup-container">
        <div className="signup-form">
          <h2>Reset password</h2>
          <InputField
            placeholder="Create a new password"
            type="password"
            label="New Password"
            value={resetPassword.password}
            required
            name="password"
            onChange={handlePasswordChange}
            error={validationErrors?.password}
          />
           <InputField
            placeholder="Re enter new password"
            type="password"
            label="Confirm Password"
            value={resetPassword.confirmPassword}
            required
            name="confirmPassword"
            onChange={handlePasswordChange}
            error={validationErrors?.confirmPassword}
          />
          <div className="signup-button-container">
            <button onClick={handleResetPassword} className="signup-button">
              Reset
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
